export { appLanding };


/*****************************************************************************/
/* 
/* LANDING APP
/*
/*****************************************************************************/

var appLanding = new Vue ({
    data: {
        leftDots: [
            {visible: false},
            {visible: false},
            {visible: false}
        ],

        rightDots: [
            {visible: false},
            {visible: false},
            {visible: false}
        ],

        contentVisible: false,

        isTearingDown: false,

        showContentPreDelay: 400,
        hideContentPreDelay: 800,

        showDotsPreDelay: 800,
        showDotsDelayBetween: 200,
        
        hideDotsPreDelay: 0,
        hideDotsDelayBetween: 200,
    },
    mounted: function() {
        this.buildUp();
    },
    methods: {
        buildUp: function() {
            this.showContent();
            this.showDots();

            window.setTimeout(this.tearDown, AppSettings.landingTimout * 1000);
        },
        tearDown: function() {
            if (this.isTearingDown) return;

            this.isTearingDown = true;

            this.hideContent();
            this.hideDots();

            this.loadHome();
        },

        showContent: function() {
            var self = this;

            window.setTimeout(function () {
                self.contentVisible = true;
            }, this.showContentPreDelay);
        },
        hideContent: function() {
            var self = this;

            window.setTimeout(function () {
                self.contentVisible = false;
            }, this.hideContentPreDelay);
        },

        showDots: function() {
            var self = this;

            window.setTimeout(function () {
                self.showDot(self.rightDots);
                self.showDot(self.leftDots);
            }, this.showDotsPreDelay);
        },
        showDot: function(dots, index) {
            if (undefined === index) {
                var index = 0;
            }
            var self = this;

            if (index < dots.length) {
                dots[index].visible = true;
                window.setTimeout(function() {
                    self.showDot(dots, index + 1);
                }, this.showDotsDelayBetween);
            }
        },

        hideDots: function() {
            var self = this;

            window.setTimeout(function () {
                self.hideDot(self.rightDots);
                self.hideDot(self.leftDots);
            }, this.hideDotsPreDelay);
        },
        hideDot: function(dots, index) {
            if (undefined === index) {
                var index = dots.length - 1;
            }
            var self = this;

            if (index >= 0) {
                dots[index].visible = false;
                window.setTimeout(function() {
                    self.hideDot(dots, index - 1);
                }, this.hideDotsDelayBetween);
            }
        },

        loadHome: function() {
            var delay = this.hideContentPreDelay + 700;

            window.setTimeout(function() {
                window.location = AppSettings.homeUrl;
            }, delay);
        }
    }
});

if (document.getElementById('appLanding')) {
    appLanding.$mount('#appLanding');
}
