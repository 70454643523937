export { appFooter };


import { EventBus } from './event-bus.js';
import { debounce } from '../modules/helpers.js';


/*****************************************************************************/
/* 
/* FOOTER APP
/*
/*****************************************************************************/

var appFooter = new Vue ({
    data: {
        pictureTitle: '',
        pictureShort: '',
        pictureInfo: '',

        pictureLinkText: '',
        pictureLinkShort: '',
        pictureLinkUrl: '',

        projectInfoLinkVisible: true,
        projectInfoCardVisible: false,
        tallerThanScreen: false
    },
    beforeMount: function() {
        EventBus.$on('project:update-picture-title', this.updatePictureTitle);
        EventBus.$on('header:mobile-menu-action', this.hideProjectInfoCard);
    },
    created: function() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed: function() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        updatePictureTitle: function(title, titleShort, linkText, linkTextShort, linkUrl) {
            if ('' === titleShort) {
                titleShort = title;
            }
            if ('' === linkTextShort) {
                linkTextShort = linkText;
            }
            if ('' != title && '' != linkText && '' === linkUrl) {
                title = title + ',';
                titleShort = titleShort + ',';
            }
            this.pictureTitle = title;
            this.pictureShort = titleShort;

            if ('' === linkUrl) {
                this.pictureInfo = linkText;
                
                this.pictureLinkText = '';
                this.pictureLinkShort = '';
                this.pictureLinkUrl = '';

                this.projectInfoLinkVisible = true;
            } else {
                this.pictureInfo = '';

                this.pictureLinkText = linkText;
                this.pictureLinkShort = linkTextShort;
                this.pictureLinkUrl = linkUrl;

                this.projectInfoLinkVisible = false;
            }
        },
        handleResize: debounce(function() {
            this.adjustFooterSize();
        }),
        adjustFooterSize: function() {
            if (this.projectInfoCardVisible) {
                var infoCardHeight = this.$refs.infoCard.clientHeight;
                var screenHeight = window.innerHeight;

                this.tallerThanScreen = infoCardHeight > screenHeight;
            }
        },
        hideProjectInfoCard: function() {
            this.projectInfoCardVisible = false;
        },
    },
    watch: {
        projectInfoCardVisible: function(newValue) {
            if (true === newValue) {
                this.$nextTick(function() {
                    this.adjustFooterSize();
                });
            }
        }
    }
});

if (document.getElementById('footer-app')) {
    appFooter.$mount('#footer-app');
}
