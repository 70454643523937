export { LayoutHelper };


/*****************************************************************************/
/* 
/* LAYOUT HELPER
/*
/*****************************************************************************/

var LayoutHelper = function() {
    this.sizes = {
        small: {
            landscape: {
                top: 120,
                bottom: 62,
                left: 12,
                right: 12,
            },
            portrait: {
                top: 120,
                bottom: 62,
                left: 12,
                right: 12,
            },
        },
        medium: {
            landscape: {
                top: 87,
                bottom: 90,
                left: 16,
                right: 16,
            },
            portrait: {
                top: 66,
                bottom: 80,
                left: 102,
                right: 102,
            },
        },
        large: {
            landscape: {
                top: 62,
                bottom: 62,
                left: 25,
                right: 70,
            },
            portrait: {
                top: 62,
                bottom: 62,
                left: 0.233, // percent
                right: 70,
            },
        },
    }
};

LayoutHelper.prototype.getCounterPosition = function(imageRatio) {
    if (window.innerWidth > AppSettings.breakpoints.medium) {
        var imageLayout = this.getImageLayout(imageRatio);
        return {
            x: imageLayout.x,
            y: imageLayout.y,
        };
    } else {
        return { x: 0, y: 0 };
    }
}

LayoutHelper.prototype.getImageLayout = function(imageRatio) {
    var imageLayout = { x: 0, y: 0, top: 0, left: 0 };

    var screenWidth = window.innerWidth;
    var screenHeight = window.innerHeight;

    var sizeClass = this.getSizeClass(screenWidth);
    var format = this.getFormat(imageRatio);
    var margins = this.sizes[sizeClass][format];

    var containerHeight = screenHeight - margins.top - margins.bottom;
    
    var left = margins.left;
    // exceptional case where left margin is relative to screen width
    if ('large' == sizeClass && 'portrait' == format) {
        var left = margins.left * screenWidth;
    }
    var containerWidth = screenWidth - left - margins.right;
        

    var containerRatio = containerWidth / containerHeight;
    if (containerRatio > imageRatio) {
        imageLayout.y = containerHeight;
        imageLayout.x = containerHeight * imageRatio;
    } else {
        imageLayout.x = containerWidth;
        imageLayout.y = containerWidth / imageRatio;
    }

    // exceptional case where left margin is relative to screen width
    if ('large' == sizeClass) {
        imageLayout.left = left;
    } else {
        imageLayout.left = (containerWidth - imageLayout.x) / 2 + margins.left;
    }
    imageLayout.top = (containerHeight - imageLayout.y) / 2 + margins.top;
    if ('large' == sizeClass) {
        imageLayout.top = margins.top;
    }

    return imageLayout;
}

LayoutHelper.prototype.getSizeClass = function(screenWidth) {
    var sizeClass = 'small';
    if (screenWidth > AppSettings.breakpoints.medium) {
        sizeClass = 'large';
    } else if (screenWidth > AppSettings.breakpoints.small) {
        sizeClass = 'medium';
    }

    return sizeClass;
}

LayoutHelper.prototype.getFormat = function(imageRatio) {
    var format = 'landscape';
    if (imageRatio <= 1) {
        format = 'portrait';
    }

    return format;
}
