export { ImagePreloader };


/*****************************************************************************/
/* 
/* IMAGE PRELOADER
/*
/*****************************************************************************/

function noop() {}

var ImagePreloader = function() {};

ImagePreloader.prototype.preloadImage = function(srcset, src) {
    return new Promise(function(resolve, reject) {
        var image = new Image();
        image.onload = resolve;
        image.onerror = resolve;
        image.srcset = srcset;
        image.src = src;
    });
}

ImagePreloader.prototype.getTimeout = function(delay) {
    return new Promise(function(resolve, reject) {
        window.setTimeout(resolve, delay);
    });
}

ImagePreloader.prototype.preload = function(images, callback, minDelay) {
    var callback = callback || noop;
    var minDelay = minDelay || 0;
    var queue = [];

    images.forEach(function(image) {
        queue.push(this.preloadImage(image.srcset, image.src));
    }, this);

    if (minDelay) {
        queue.push(this.getTimeout(minDelay));
    }

    Promise.all(queue)
        .then(callback)
        .catch(function(err) {console.log(err)});
}
