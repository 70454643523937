export { appProject };


import { EventBus } from './event-bus.js';
import { ImagePreloader } from '../modules/image-preloader.js';
import { LayoutHelper } from '../modules/layout-helper.js';
import { Velocity } from '../modules/velocity.js';


/*****************************************************************************/
/* 
/* PROJECT APP / IMAGE GALLERY
/*
/*****************************************************************************/

function createProjectApp() { return new Vue ({
    data: {
        theme: 'default',

        basePath: AppSettings.pagePath,

        images: AppSettings.projectImages,
        imageCount: AppSettings.projectImages.length,

        isVisible: false,

        currentImageIdx: 0,
        currentCounter: '',
        classCounterInvisible: '',

        slides: [],

        prevSlideIdx: 0,
        currentSlideIdx: 1,
        nextSlideIdx: 2,

        dragging: false,
        cursorStartX: 0,
        cursorCurrentX: 0,
        draggingSpeed: 1.2,
        dragVelocityThreshold: 700,

        dragLock: false,
        dragLockTime: 0.3,

        loader: null,
        layout: null,
        velocity: null,

        videos: [],
    },
    beforeMount: function() {
        this.loader = new ImagePreloader();
        this.layout = new LayoutHelper();
        this.velocity = new Velocity();

        this.initSlides();
        this.setCurrentImageFromUrl();

        window.addEventListener('resize', this.handleResize);
    },
    mounted: function() {
        this.initVideos();

        // wait a bit for the container divs for the Vimeo player to be added to the DOM
        var self = this;
        this.$nextTick(function() {
            self.videos.map(function(video) {
                return video.player = self.createVideoPlayer(video);
            });

            self.updateSlides();
            self.preloadImageSet('init');
        });
    },
    methods: {
        initSlides: function() {
            for (var idx = 0; idx < 3; idx++) {
                this.slides.push({
                    src: '',
                    srcset: '',
                    sizes: '',
                    aspectRatio: 1,
                    counter: '',
                    counterPos: { x: 0, y: 0 },
                    classFormat: '',
                    classPosition: '',
                    classHidden: '',
                    classInvisible: 'invisible',
                    classZindex: '',
                    classPlaying: '',
                    imgObj: new Image,
                    image: null,
                });   
            }

            this.slides[this.prevSlideIdx].classPosition = 'left-off';
            this.slides[this.nextSlideIdx].classPosition = 'right-off';
        },
        initVideos: function() {
            var self = this;
            this.images.filter(function(img) { return img.vimeoId }).map(function(image) {
                var video = {
                    vimeoId: image.vimeoId,
                    aspectRatio: image.aspectRatio,
                    classPlaying: '',
                    classPosition: '',
                    styleDragPosition: '',
                };
                self.videos.push(video);
                image.video = video;
            });
        },

        createVideoPlayer: function(video) {
            var options = {
                id: video.vimeoId,
                background: true,
                loop: true,
                muted: true,
                dnt: true,
            };
            var player = new Vimeo.Player('js-video-' + video.vimeoId, options);
            player.pause();

            var self = this;
            player.ready().then(function() {
                self.updatePlayerLayout(video);
            });

            return player;
        },

        handleResize: function() {
            this.updateCounterPosition(this.currentSlideIdx);
            this.updateCounterPosition(this.nextSlideIdx);
            this.updateCounterPosition(this.prevSlideIdx);

            var self = this;
            this.videos.map(function(video) { self.updatePlayerLayout(video); });
        },

        updateCounterPosition: function(slideIdx) {
            var slide = this.slides[slideIdx];
            slide.counterPos = this.layout.getCounterPosition(slide.aspectRatio);
        },

        updatePlayerLayout: function(video) {
            var playerLayout = this.layout.getImageLayout(video.aspectRatio);

            var size = 'width: '+ playerLayout.x +'px; height: '+ playerLayout.y +'px; ';
            var position = 'top: '+ playerLayout.top +'px; left: '+ playerLayout.left +'px;';
            
            video.player.element.style = size;
            video.player._originalElement.style = position;
        },


        preloadImageSet: function(mode) {
            switch (mode) {
                case 'init':
                case 'next':
                    var images = [];
                    for (var i = 1; i < 4; i++) {
                        images.push(this.images[this.currentImageIdxPlus(i)]);
                    }
                    this.loader.preload(images);
                    break;
                case 'prev':
                    var images = [];
                    for (var i = 1; i < 4; i++) {
                        images.push(this.images[this.currentImageIdxMinus(i)]);
                    }
                    this.loader.preload(images);
                    break;
            }
        },

        setCurrentImageFromUrl: function() {
            var match = AppSettings.urlSegment.match(/^image\-(\d{1,2})/);
            if (match) {
                var imageNumber = match[1];
                this.currentImageIdx = imageNumber - 1;

                history.replaceState(null, '', this.basePath);
            }
        },

        updateSlides: function() {
            this.updateSlide(this.currentSlideIdx, this.currentImageIdx);

            if (this.imageCount > 1) {
                this.updateSlide(this.nextSlideIdx, this.nextImageIdx());
                this.updateSlide(this.prevSlideIdx, this.prevImageIdx());
            }
        },

        updateSlide: function(slideIdx, imageIdx) {
            var slide = this.slides[slideIdx];
            var image = this.images[imageIdx];
            slide.image = image;
            var slideElm =  this.$refs.slides[slideIdx];

            slide.classZindex = (slideIdx === this.currentSlideIdx) ? 'front' : 'back';

            var position = '';
            switch (slideIdx) {
            case this.prevSlideIdx:
                position = 'left-off';
                break;
            case this.nextSlideIdx:
                position = 'right-off';
                break;
            }

            
            // wait a tiny bit before bringing slide with next image into view
            // prevents flashing in Desktop Safari when image source is updated while image being visible
            window.setTimeout(function() {
                slide.classPosition = position;
            }, this.isDesktopSafari() ? 100 : 0);


            slide.classHidden = (slideIdx === this.hiddenSlideIdx) ? 'hidden' : '';
            // time needed for the hidden slide of the three to make its way to the other side 
            window.setTimeout(function() {slide.classHidden = '';}, 100);

            slide.classFormat = image.format;

            // image counter example: "03 / 12"
            slide.counter = this.getCounterStr(imageIdx);
            if (slideIdx === this.currentSlideIdx) {
                this.currentCounter = slide.counter;
            }

            slide.aspectRatio = image.aspectRatio;
            this.updateCounterPosition(slideIdx);

            if (slideIdx === this.currentSlideIdx) {
                var title = image.title;
                var titleShort = image.titleShort;
                if (image.hideTitle) {
                    title = '';
                    titleShort = '';
                }
                EventBus.$emit('project:update-picture-title', title, titleShort, image.linkText, image.linkTextShort, image.link);
                this.updateColors();
            }

            // reset of src is necessary to trigger onload for already loaded images on Mobile Safari
            slide.imgObj.src = '';
            slide.imgObj.onload = this.showImage(slideIdx);

            slide.imgObj.sizes = image.sizes;
            slide.imgObj.srcset = image.srcset;
            slide.imgObj.src = image.src;


            // Video Player

            if (image.vimeoId) {
                var video = image.video;

                // on Desktop Safari we wait a tiny bit to prevent flashing on image update (see above)
                // --> updating of videos (bringing them into view) has to be syncronized to this
                // --> so we wait the same amount of time
                window.setTimeout(function() {
                    video.classPosition = position;
                }, this.isDesktopSafari() ? 100 : 0);

                if (video.player) {
                    if (this.currentSlideIdx === slideIdx) {
                        // due to a bug in Desktop Safari / Vimeo Embed Player some videos start with a white flash
                        // we hide this with a delay before showing the video player
                        var delay = this.isDesktopSafari() ? 700 : 0;
                        var self = this;
                        video.player.getPaused().then(function(paused) {
                            if (paused) {
                                video.player.play().then(function() {
                                    window.setTimeout(function() {
                                        // hide slide
                                        slide.classPlaying = 'playing';
                                        // show player
                                        video.classPlaying = 'playing';
                                    }, delay);
                                }, function(msg) {console.log(msg)});
                            }
                        });
                    } else {
                        // on Desktop Safari we wait a little bit before stopping a video
                        // to syncronize it with the waiting before a new image / video is shown
                        var delay = this.isDesktopSafari() ? 100 : 0;
                        window.setTimeout(function() {
                            // hide player
                            video.classPlaying = '';
                             // show slide
                            slide.classPlaying = '';
                            // pause and rewind video
                            video.player.getPaused().then(function(paused) {
                                if (!paused) {
                                    video.player.pause();
                                    video.player.setCurrentTime(0);
                                }
                            });
                        }, delay);
                    }
                }
            }
        },

        showImage: function(slideIdx) {
            var slide = this.slides[slideIdx];

            var self = this;
            return function() {
                slide.sizes = slide.imgObj.sizes;
                slide.srcset = slide.imgObj.srcset;
                slide.src = slide.imgObj.src;

                self.isVisible = true;
            }
        },

        getCounterStr: function(imageIdx) {
            return (this.pad(imageIdx + 1, 2)) + ' / ' + this.pad(this.imageCount, 2)
        },

        nextImage: function() {
            this.currentImageIdx = this.nextImageIdx();

            this.hiddenSlideIdx = this.prevSlideIdx;

            this.prevSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.nextSlideIdx;
            this.nextSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.preloadImageSet('next');
        },
        prevImage: function() {
            this.currentImageIdx = this.prevImageIdx();

            this.hiddenSlideIdx = this.nextSlideIdx;

            this.nextSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.prevSlideIdx;
            this.prevSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.preloadImageSet('prev');
        },

        nextImageIdx: function() {
            return this.currentImageIdxPlus(1);
        },
        prevImageIdx: function() {
            return this.currentImageIdxMinus(1);
        },

        currentImageIdxPlus: function(offset) {
            return (this.currentImageIdx + offset) % this.imageCount;  
        },
        currentImageIdxMinus: function(offset) {
            return (this.currentImageIdx - (offset % this.imageCount) + this.imageCount) % this.imageCount;
        },


        isLast: function() {
            return this.imageCount == this.currentImageIdx + 1;
        },


        updateColors: function() {
            var newTheme = this.images[this.currentImageIdx].theme;
            if (newTheme != this.theme) {
                const el = document.body;
                el.classList.replace('color-schema-' + this.theme, 'color-schema-' + newTheme);
                this.theme = newTheme;
            }
        },

        pad: function(num, size) {
            var s = '00' + num;
            return s.substr(s.length - size);
        },


        /* Image Swipe Code starts here */

        // inspired by: https://www.labnol.org/code/19616-detect-touch-screen-javascript
        isTouchDevice: function() {
            return (('ontouchstart' in window)
                 || (navigator.MaxTouchPoints > 0)
                 || (navigator.msMaxTouchPoints > 0));
        },

        isDesktopSafari: function() {
            return navigator.userAgent.indexOf('Safari') != -1
                && navigator.userAgent.indexOf('Macintosh') != -1
                && navigator.userAgent.indexOf('Chrome') == -1
                && !this.isTouchDevice();
        },

        getCursorX: function(event) {
            if (event.touches && event.touches.length) {
                // touch
                return event.touches[0].pageX;
            }

            if (event.pageX && event.pageY) {
                // mouse
                return event.pageX;
            }

            return 0;
        },

        startDrag: function(event) {
            if (this.dragLock) return;
            if (this.imageCount <= 1) return;
            
            this.dragging = true;
            this.cursorStartX = this.getCursorX(event);
            this.cursorCurrentX = this.cursorStartX;

            this.velocity.reset();
        },
        drag: function(event) {
            if (!this.dragging) return;

            this.cursorCurrentX = this.getCursorX(event);
            this.velocity.updatePosition(this.cursorCurrentX);
        },
        stopDrag: function(event) {
            if (!this.dragging) return;

            this.dragging = false;

            var dragVelocity = Math.abs(this.velocity.getVelocity());
            var fastEnough = dragVelocity > this.dragVelocityThreshold;

            var farEnough = Math.abs(this.diffX) > this.dragDistanceThreshold;

            if (fastEnough || farEnough) {
                if (this.diffX < 0) this.nextImage();
                if (this.diffX > 0) this.prevImage();
            }

            this.dragLock = true;
            var self = this;
            window.setTimeout(function() {
                self.dragLock = false;
            }, this.dragLockTime * 1000);
        },

        styleDragPosition: function(slideIdx) {
            var image = this.slides[slideIdx].image;
            if (!this.dragging) {
                if (image && image.vimeoId) {
                    image.video.styleDragPosition = '';
                }

                return '';
            }

            var draggingDistance = this.diffX * this.draggingSpeed;

            var style = '';

            if (slideIdx === this.currentSlideIdx) {
                style = 'transform: translate3d('+ draggingDistance +'px, 0, 0)'
            }
            
            if (slideIdx === this.nextSlideIdx
                && this.draggingLeft) {
                style = 'transform: translate3d(calc(100vw + '+ (draggingDistance) +'px), 0, 0)';
            }

            if (slideIdx === this.prevSlideIdx
                && this.draggingRight) {
                style = 'transform: translate3d(calc(-100vw + '+ (draggingDistance) +'px), 0, 0)';
            }

            
            if (image && image.vimeoId) {
                // For smooth dragging in both directions 3 slides are used: prev, current, next.
                // If there are only 2 images, one of them is repeated on the off-screen slides:
                // The current slide contains image A, prev and next slides contain image B.
                // However, to safe resources there only exist a maximum of 2 video players.
                // Depending on dragging direction the video player's position is attached
                // to the slide leaving the screen or the one coming onto the screen.
                if (2 > this.imageCount
                    || (this.draggingLeft && slideIdx !== this.prevSlideIdx)
                    || (this.draggingRight && slideIdx !== this.nextSlideIdx)
                ) {
                    image.video.styleDragPosition = style;
                }
            }

            return style;
        },

        styleCounterPosition: function(slideIdx) {
            var slide = this.slides[slideIdx];
            return 'left: '+ slide.counterPos.x +'px; top: '+ slide.counterPos.y +'px;';
        },
    },
    computed: {

        classDragging: function() {
            return this.dragging ? 'dragging' : '';
        },

        classIsTouchDevice: function() {
            return this.isTouchDevice() ? 'touch' : '';
        },
        classIsVisible: function() {
            return this.isVisible ? '' : 'invisible';
        },

        diffX: function() {
            return this.cursorCurrentX - this.cursorStartX;
        },

        deviceWidth: function() {
            return window.innerWidth;
        },
        dragDistanceThreshold: function() {
            return this.deviceWidth / 5;
        },

        draggingLeft: function() {
            return this.diffX < 0;
        },

        draggingRight: function() {
            return this.diffX > 0;
        },
    },
});}

var appProject;
if (document.getElementById('project-app')) {
    appProject = createProjectApp();
    appProject.$mount('#project-app');
}
