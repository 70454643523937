export { appHeader };


import { EventBus } from './event-bus.js';


/*****************************************************************************/
/* 
/* HEADER APP
/*
/*****************************************************************************/

const states = {
    NONE: 'none',
    MENU_CLOSED: 'menu_closed',
    MENU_OPEN: 'menu_open',
    PROJECTS: 'selected_projects',
    PROJECT_TITLE: 'project_title',
    PAGE_TITLE: 'page_title',
    PROJECTS_IN_CATEGORY: 'projects_in_categories',
    EXHIBITIONS: 'exhibitions',
    ABOUT: 'about',
}

var appHeader = new Vue ({
    data: {
        vitaCardVisible: false,
        projectsCardVisible: false,
        exhibitionsCardVisible: false,
        aboutCardVisible: false,

        mobileMenuVisible: false,

        mobileMenuTitle: '',
        mobileMenuState: states.NONE,

        hasCategories: AppSettings.hasCategories,
        categories: [],
        categoryTitle: '',
        projectsInCategory: [],
        currentCategoryIndex: undefined,

    },
    beforeMount: function() {
        this.initCatgegories();

        if ('project' === AppSettings.templateName) {
            this.mobileMenuTitle = AppSettings.pageTitle;
            this.mobileMenuState = states.PROJECT_TITLE;
        } else if ('page' === AppSettings.templateName) {
            this.mobileMenuTitle = AppSettings.pageTitle;
            this.mobileMenuState = states.PAGE_TITLE;
        } else {
            this.mobileMenuState = states.MENU_CLOSED;
        }

        window.addEventListener('resize', this.adjustViewportHeight);
    },
    methods: {
        adjustViewportHeight: function() {
              document.documentElement.style.setProperty('--viewport-height', window.innerHeight + "px");
        },

        mobileMenuAction: function() {
            switch (this.mobileMenuState) {
                case states.MENU_OPEN:
                    this.mobileMenuState = states.MENU_CLOSED;
                    break;
                case states.MENU_CLOSED:
                    this.mobileMenuState = states.MENU_OPEN;
                    break;
                case states.PROJECT_TITLE:
                    this.projectsCardVisible = true;
                    if (undefined !== this.currentCategoryIndex) {
                        this.mobileMenuState = states.PROJECTS_IN_CATEGORY;
                    } else {
                        this.mobileMenuState = states.PROJECTS;
                    }
                    break;
                case states.PAGE_TITLE:
                    this.aboutCardVisible = true;
                    this.mobileMenuState = states.ABOUT;
                    break;
            }
        },
        showCard: function(stateName) {
            this.mobileMenuState = states[stateName];
        },
        navigateBack: function() {
            switch (this.mobileMenuState) {
                case states.PROJECTS_IN_CATEGORY:
                    this.categoryTitle = '';
                    this.projectsInCategory = [];
                    this.mobileMenuState = states.PROJECTS;
                    break;

                case states.PROJECTS:
                    this.projectsCardVisible = false;
                    this.mobileMenuState = states.MENU_OPEN;
                    break;

                case states.ABOUT:
                    this.aboutCardVisible = false;
                    this.mobileMenuState = states.MENU_OPEN;
                    break;

                case states.EXHIBITIONS:
                    this.exhibitionsCardVisible = false;
                    this.mobileMenuState = states.MENU_OPEN;
                    break;
            }
        },
        closeCard: function() {
            switch (this.mobileMenuState) {
                case states.PROJECTS:
                case states.PROJECTS_IN_CATEGORY:
                    this.projectsCardVisible = false;
                    this.categoryTitle = '';
                    this.projectsInCategory = [];
                    break;
                case states.ABOUT:
                    this.aboutCardVisible = false;
                    break;
                case states.EXHIBITIONS:
                    this.exhibitionsCardVisible = false;
                    break;
            }

            this.mobileMenuState = states.MENU_CLOSED;
        },
        initCatgegories: function() {
            if (!this.hasCategories) return;
            
            this.categories = AppSettings.categories;

            // if it was not too long ago restore category menu state
            var now = new Date();
            var expirationTime = localStorage.getItem('expirationTime');
            if (null === expirationTime || now.getTime() > expirationTime) {
                localStorage.clear();
                return;
            }
            if ('project' == AppSettings.templateName) {
                var catIndex = localStorage.getItem(AppSettings.pageName);
                if (null !== catIndex) {
                    this.setCategory(catIndex);
                }
            }
        },
        setCategory: function(index) {
            this.currentCategoryIndex = index;
            this.categoryTitle = this.categories[index].title;
            this.projectsInCategory = this.categories[index].projects;

            this.mobileMenuState = states.PROJECTS_IN_CATEGORY;
        },
        clickProject: function(projectSlug) {
            localStorage.setItem(projectSlug, this.currentCategoryIndex);

            // remember category that was used to navigate to this project
            // for half an hour
            var now = new Date();
            var ttl = 1800000; // 30min = 1800s = 1800000ms
            localStorage.setItem('expirationTime', now.getTime() + ttl);
        },
    },
    watch: {
        mobileMenuState: function() {
            switch (this.mobileMenuState) {
                case states.MENU_OPEN:
                    this.mobileMenuTitle = 'Menu';
                    this.mobileMenuVisible = true;
                    break;
                case states.MENU_CLOSED:
                    this.mobileMenuTitle = 'Menu';
                    this.mobileMenuVisible = false;
                    break;

                case states.ABOUT:
                    this.aboutCardVisible = true;
                    break;
                case states.PROJECTS:
                    this.projectsCardVisible = true;
                    break;
                case states.EXHIBITIONS:
                    this.exhibitionsCardVisible = true;
                    break;
            }
            EventBus.$emit('header:mobile-menu-action');
        }
    },
    computed: {
        projectsInCategoryVisibleOnMobile: function() {
            if (this.mobileMenuState == states.PROJECTS_IN_CATEGORY) {
                return 'projects-in-category-visible-on-mobile';
            }
            return '';
        }
    }
});

if (document.getElementById('header-app')) {
    appHeader.$mount('#header-app');
}
