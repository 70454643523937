export { appBlockSlideshow };


import { ImagePreloader } from '../modules/image-preloader.js';


/*****************************************************************************/
/* 
/* BLOCK SLIDESHOW APP / CONTENT BLOCK ON PAGE
/*
/*****************************************************************************/

var appBlockSlideshow = new Vue ({
    data: {
        rows: AppSettings.slideshow,

        intervalObj: null,
        currentRowIdx: 0,
        active: false,

        fadeOutTime: 0.7,

        loader: null
    },
    beforeMount: function() {
        this.loader = new ImagePreloader();
    },
    mounted: function() {
        var self = this;
        this.preloadImages(0, function() {
            self.active = true;
            self.startLoop();
        });
    },
    methods: {
        startLoop: function() {
            var delayStep1 = this.images.length * this.delayBetweenImages;
            this.showImages();

            var delayStep2 = delayStep1 + this.delayAfterCompletion;
            window.setTimeout(this.hideImages, delayStep2 * 1000);

            var delayStep3 = delayStep2 + this.fadeOutTime;
            window.setTimeout(this.nextRow, delayStep3 * 1000);
        },
        nextRow: function() {
            var nextRowIdx = (this.currentRowIdx + 1) % this.rows.length;
            var self = this;
            this.preloadImages(nextRowIdx, function() {
                self.currentRowIdx = nextRowIdx;
                self.startLoop();
            }, this.delayAfterFadeout);
        },
        preloadImages: function(rowIdx, callback, minDelay) {
            var images = this.getImagesForRow(rowIdx);
            this.loader.preload(images, callback, minDelay);
        },
        hideImages: function() {
            for (var index = 0; index < this.images.length; index++) {
                this.images[index].visible = false;
            }
            // next two lines mark this.images as dirty for Vue to update/re-render
            var imgObj = this.images[0];
            this.$set(this.images, 0, imgObj);
        },
        showImages: function() {
            this.intervalObj = window.setInterval(this.showImage(0), this.delayBetweenImages * 1000);
        },
        showImage: function(startIdx) {
            var self = this;
            var index = startIdx;

            return function() {
                var imgObj = self.images[index];
                imgObj.visible = true;
                self.$set(self.images, index, imgObj);
                index++;
                if (self.images.length === index) {
                    window.clearInterval(self.intervalObj);
                }
            }
        },
        isVerySmallScreen: function() {
            return window.innerWidth <= AppSettings.breakpoints.verySmall;
        },
        visibleClass: function(index) {
            return (this.images[index].visible) ? 'visible' : '';
        },
        getImagesForRow: function(rowIdx) {
            var images = this.rows[this.currentRowIdx].images;

            if (this.isVerySmallScreen()) {
                var imagesMax = this.rows[rowIdx].imagesMaxOnMobile;
                return images.slice(0, imagesMax);
            } else {
                return images;
            }
        }
    },
    computed: {
        delayBetweenImages: function() {
            if (!this.active) return 0;
            return this.rows[this.currentRowIdx].delays.delayBetweenImages;
        },
        delayAfterCompletion: function() {
            if (!this.active) return 0;
            return this.rows[this.currentRowIdx].delays.delayAfterCompletion;
        },
        delayAfterFadeout: function() {
            if (!this.active) return 0;
            return this.rows[this.currentRowIdx].delays.delayAfterFadeout;
        },
        images: function() {
            if (!this.active) return [];
            return this.getImagesForRow(this.currentRowIdx);
        }
    }


});

if (document.getElementById('appBlockSlideshow')) {
    appBlockSlideshow.$mount('#appBlockSlideshow');
}
